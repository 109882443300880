import {
	VehicleRoadTaxesFilters,
	VehicleRoadTaxStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsRoadTaxes(
	value: VehicleRoadTaxStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === VehicleRoadTaxStatusEnum.ACTIVE) {
		let filters: VehicleRoadTaxesFilters = {
			statuses: [VehicleRoadTaxStatusEnum.ACTIVE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_ROAD_TAXES };
	} else if (value === VehicleRoadTaxStatusEnum.EXPIRED) {
		let filters: VehicleRoadTaxesFilters = {
			statuses: [VehicleRoadTaxStatusEnum.EXPIRED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_ROAD_TAXES };
	} else if (value === VehicleRoadTaxStatusEnum.PENDING) {
		let filters: VehicleRoadTaxesFilters = {
			statuses: [VehicleRoadTaxStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_ROAD_TAXES };
	} else {
		let filters: VehicleRoadTaxesFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_ROAD_TAXES };
	}
}
