import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
	name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
	transform(value: DateTime | string): string {
		if (!value) {
			return '';
		}

		const dateTime =
			typeof value === 'string' ? DateTime.fromISO(value) : value;

		const diff = dateTime.diffNow().shiftTo('seconds').toObject();

		// If the difference is less than 5 seconds, return 'just now'
		if (Math.abs(diff.seconds) < 5) {
			return 'just now';
		}
		// Otherwise, return a shortened version of the relative time
		else {
			const relative = dateTime.toRelative({ locale: 'en' });
			if (!relative) return '';

			// Replace words with abbreviations
			return (
				relative
					.replace(' seconds', 's')
					.replace(' minutes', 'm')
					.replace(' minute', 'm')
					.replace(' hours', 'h')
					.replace(' hour', 'h')
					.replace(' days', 'd')
					.replace(' day', 'd')
					.replace(' weeks', 'w')
					.replace(' week', 'w')
					.replace(' months', 'mo')
					.replace(' month', 'mo')
					.replace(' years', 'y')
					.replace(' year', 'y')
					.replace(' ago', '')
					.trim() + ' ago'
			);
		}
	}
}
