<ng-container *ngIf="showTrailing">
	<ng-content></ng-content>
</ng-container>

<div [matTooltip]="tooltip" class="flex items-center w-full">
	<mat-icon
		[ngClass]="iconClass"
		*ngIf="svgIcon"
		class="icon-size-5 mr-1.5"
		[svgIcon]="svgIcon"></mat-icon>
	<div class="flex justify-between w-full items-center">
		<span *ngIf="content" class="text-md truncate">
			{{ content | transloco }}</span
		>
		<app-color-bubble *ngIf="color" [color]="color" class="mr-2" />
	</div>
</div>
