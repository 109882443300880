import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-floating-menu-button',
	template: `
		<button
			class="floating-menu-button flex items-center justify-center w-14 h-14 border-none rounded-full bg-blue-500 text-white cursor-pointer transition-colors duration-300 ease-in-out hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
			(click)="onButtonClicked.emit()"
			[disabled]="isDisabled">
			<mat-icon [ngStyle]="{ color: iconColor }">{{ svgIcon }}</mat-icon>
		</button>
	`,
	standalone: true,
	imports: [MatIconModule, CommonModule],
})
export class FloatingMenuButtonComponent {
	@Input() svgIcon: string = '';
	@Input() iconColor: string = 'white'; // Default color is white
	@Input() isDisabled: boolean = false; // Add isDisabled input property
	@Output() onButtonClicked: EventEmitter<void> = new EventEmitter<void>();
}
