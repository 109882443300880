<mat-form-field
	class="fuse-mat-dense fuse-mat-rounded w-full"
	[subscriptSizing]="'dynamic'">
	<!-- Input -->
	<mat-icon
		class="icon-size-5 hidden sm:block"
		matPrefix
		[svgIcon]="'search'"></mat-icon>
	<input
		matInput
		[formControl]="filterQueryControl"
		[autocomplete]="'on'"
		[placeholder]="'shared|search' | transloco"
		(keyup)="onFilterQueryChange($event.target.value)" />
	<!-- Case Sensitive -->
	<button
		type="button"
		[ngClass]="{
			'bg-accent-300': filterQueryCaseSensitive,
		}"
		mat-icon-button
		matSuffix
		[matTooltip]="
			filterQueryCaseSensitive
				? ('shared|table-search-bar|set-filtering-mode-case-sensitive'
					| transloco)
				: ('shared|table-search-bar|set-filtering-mode-case-insensitive'
					| transloco)
		"
		(click)="onFilterQueryCaseSensitiveClick()">
		<mat-icon
			class="icon-size-5"
			[ngClass]="{
				'text-on-accent-100': filterQueryCaseSensitive,
			}">
			<span class="material-symbols-outlined">match_case</span>
		</mat-icon>
	</button>
	<!-- Match Exact -->
	<button
		type="button"
		[ngClass]="{
			'bg-accent-300': filterQueryMatchExact,
		}"
		mat-icon-button
		matSuffix
		[matTooltip]="
			filterQueryMatchExact
				? ('shared|table-search-bar|set-filtering-mode-match-any-term'
					| transloco)
				: ('shared|table-search-bar|set-filtering-mode-match-whole-phrase'
					| transloco)
		"
		(click)="onFilterQueryMatchExactClick()">
		<mat-icon
			class="icon-size-5"
			[ngClass]="{
				'text-on-accent-100': filterQueryMatchExact,
			}">
			<span class="material-symbols-outlined">match_word</span>
		</mat-icon>
	</button>
	<!-- Clear -->
	<button
		class="hidden sm:block"
		type="button"
		mat-icon-button
		matSuffix
		[matTooltip]="
			'shared|table-search-bar|clear-filter-search-query' | transloco
		"
		(click)="onFilterQueryClearClick()">
		<mat-icon class="icon-size-5" [svgIcon]="'clear'"></mat-icon>
	</button>
</mat-form-field>
