import { Clipboard } from '@angular/cdk/clipboard';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { AppContextService } from 'app/shared/services/app-context.service';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { ModulesEnum } from '../../list-table/modules.enum';

import { HeaderDataModel } from './record-header.model';

@Component({
	selector: 'app-record-header',
	templateUrl: './record-header.component.html',
	animations: fuseAnimations,
})
export class RecordHeaderComponent<T, TRecordModel> {
	@Input({ required: true }) headerData: HeaderDataModel<T>;
	@Input() recordData: TRecordModel;
	@Input({ required: true }) isNew: boolean;
	@Input({ required: true }) isEditMode: boolean;
	@Input({ required: true }) isDrawerOpened: boolean;
	@Input() showBackToList = true;
	@Input() icon: string;
	@Input() moduleName: string;
	@Input() module: ModulesEnum;
	@Input() iconTooltip: string;
	@Input() isMobileView: boolean;
	@Input() isSaveButtonActive: boolean = true;
	@Input() isDraft: boolean;
	@Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onToggleSidebar: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSave: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
	@Output() onMarkAsReady: EventEmitter<void> = new EventEmitter<void>();
	@Output() exportRecord: EventEmitter<void> = new EventEmitter<void>();

	@Input() hideMenuToggle: boolean;
	public allowEdit: boolean;
	public allowCreate: boolean;
	public isPresent = this._context.timeMachineService.isPresent;
	@ViewChild('anchor', { read: ElementRef }) anchor!: ElementRef;
	@ViewChild('templateRef')
	templateRef: TemplateRef<unknown>;
	templatePortal: TemplatePortal;
	overlayRef: OverlayRef;
	copiedTitleToClipboard = false;
	private _clipboard = inject(Clipboard);

	constructor(
		private _context: AppContextService,
		private _overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
	) {
		this.allowEdit = this._context.authzService.allowEdit();
		this.allowCreate = this._context.authzService.allowCreate();
	}

	ngAfterViewInit() {
		this.templatePortal = new TemplatePortal(
			this.templateRef,
			this._viewContainerRef,
		);
	}

	onCopyTitle() {
		this._clipboard.copy(this.headerData.title);
		this.copiedTitleToClipboard = true;
		setTimeout(() => {
			this.copiedTitleToClipboard = false;
		}, 1500);
	}

	showHeaderMenu() {
		const positionStrategy = this._overlay
			.position()
			.flexibleConnectedTo(this.anchor)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]);
		this.overlayRef = this._overlay.create({ positionStrategy });
		const componentRef = this.overlayRef.attach(this.templatePortal);
		const subscription = this.overlayRef
			.outsidePointerEvents()
			.subscribe(() => {
				this.overlayRef.detach();
				subscription.unsubscribe();
			});
	}
}
