import { NgIf, CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	Input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthzService } from 'app/shared/services/authz.service';

import { FloatingMenuButtonComponent } from '../containers/floating-menu-container/floating-menu-button.component';
import { FloatingMenuContainerComponent } from '../containers/floating-menu-container/floating-menu-container.component';

@Component({
	selector: 'app-floating-menu',
	templateUrl: 'floating-menu.component.html',
	styleUrls: [],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		FloatingMenuButtonComponent,
		FloatingMenuContainerComponent,
		NgIf,
		MatIconModule,
		MatButtonModule,
	],
})
export class FloatingMenuComponent {
	@Output() editButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() deleteButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() saveButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() exportButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() toggleDrawerClicked: EventEmitter<void> = new EventEmitter<void>();

	@Input() isSaveButtonActive: boolean = false;
	@Input() isEditMode: boolean = false;
	@Input() isDrawerOpened: boolean = false;
	@Input() isMobileView: boolean = false;

	@Input() showEditButton: boolean = true;
	@Input() showCancelButton: boolean = true;
	@Input() showExportButton: boolean = true;
	@Input() showToggleDrawerButton: boolean = true;

	allowDelete: boolean;
	allowEdit: boolean;
	allowShare: boolean;

	menuOpened: boolean = false;

	constructor(private _authzService: AuthzService) {
		this.allowDelete = this._authzService.allowDelete();
		this.allowEdit = this._authzService.allowEdit();
	}

	public toggleMenu(): void {
		this.menuOpened = !this.menuOpened;
	}

	public onEditButtonClick(): void {
		this.editButtonClicked.emit();
	}

	public onDeleteButtonClick(): void {
		this.deleteButtonClicked.emit();
	}

	public onSaveButtonClick(): void {
		if (this.isSaveButtonActive) {
			this.saveButtonClicked.emit();
		}
	}

	public onCancelButtonClick(): void {
		this.cancelButtonClicked.emit();
	}

	public onExportButtonClick(): void {
		this.exportButtonClicked.emit();
	}

	public onToggleDrawerClick(): void {
		this.toggleDrawerClicked.emit();
	}
}
