import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ApiRequestFilters } from 'ultramarine-shared';

@Component({
	selector: 'app-table-search-bar',
	templateUrl: './table-search-bar.component.html',
	styleUrls: ['./table-search-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSearchBarComponent<T extends ApiRequestFilters> {
	inputState: string = '';
	@Input() contextSelections: T;

	@Input() set queryParamQuery(query: string) {
		this.filterQueryControl.setValue(query);
		this.filterQueryChange.emit(this.filterQueryControl.value);
	}

	@Input() set queryParamCaseSensitive(caseSensitive: boolean) {
		this.filterQueryCaseSensitive = caseSensitive;
		this.filterQueryCaseSensitiveClicked.emit(caseSensitive);
	}

	@Input() set queryParamMatchExact(matchExact: boolean) {
		this.filterQueryMatchExact = matchExact;
		this.filterQueryMatchExactClicked.emit(matchExact);
	}

	@Output() filterQueryChange: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() filterQueryCaseSensitiveClicked: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	@Output() filterQueryMatchExactClicked: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	public filterQueryControl: UntypedFormControl = new UntypedFormControl('');
	public filterQueryCaseSensitive: boolean = false;
	public filterQueryMatchExact: boolean = false;
	public isOpen = false;

	public onFilterQueryCaseSensitiveClick(): void {
		this.filterQueryCaseSensitive = !this.filterQueryCaseSensitive;
		this.filterQueryCaseSensitiveClicked.emit(this.filterQueryCaseSensitive);
	}

	public onFilterQueryMatchExactClick(): void {
		this.filterQueryMatchExact = !this.filterQueryMatchExact;
		this.filterQueryMatchExactClicked.emit(this.filterQueryMatchExact);
	}

	public onFilterQueryClearClick(): void {
		this.filterQueryControl.setValue('');
		this.filterQueryChange.emit(this.filterQueryControl.value);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.contextSelections) {
			if (this.contextSelections?.query && this.filterQueryControl) {
				this.filterQueryControl.setValue(this.contextSelections.query);
				this.filterQueryChange.emit(this.filterQueryControl.value);
			}
		}
	}

	public onFilterQueryChange(input: string): void {
		if (input !== this.inputState) {
			this.inputState = input;
			this.filterQueryChange.emit(input);
		}
	}
}
