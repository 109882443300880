import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ColorBubbleComponent } from '../../color-bubble/color-bubble.component';

@Component({
	selector: 'a[ultra-menu-item], button[ultra-menu-item]',
	templateUrl: 'ultra-menu-item.component.html',
	standalone: true,
	imports: [
		MatIconModule,
		NgIf,
		MatTooltipModule,
		TranslocoModule,
		NgClass,
		ColorBubbleComponent,
	],
})
export class UltraMenuItemComponent {
	@Input() disabled: boolean | null = null;
	@Input() svgIcon?: string;
	@Input() color: string;
	@Input() content?: string;
	@Input() tooltip?: string;
	@Input() iconClass?: string;
	@Input() showTrailing: boolean;

	@HostBinding('attr.disabled') get isDisabled() {
		return this.disabled === true ? '' : null;
	}

	private isFocused = false;
	@HostListener('focus', ['$event'])
	onFocus(event: FocusEvent) {
		this.isFocused = true;
	}

	@HostListener('blur', ['$event'])
	onBlur(event: FocusEvent) {
		this.isFocused = false;
	}
	// Set host classes conditionally based on the disabled state
	@HostBinding('class') get className(): string {
		const baseClasses =
			'flex items-center min-h-9 px-1 mx-1 rounded-md duration-100 border border-transparent';
		const enabledClasses = 'cursor-pointer hover:bg-hover';
		const disabledClasses = 'cursor-not-allowed';

		return `${baseClasses} ${this.disabled ? disabledClasses : enabledClasses} ${this.isFocused ? 'focused border-blue-500' : ''}`;
	}
}
