<div class="ultramarine-page-footer">
	<div class="flex-1 min-w-0 flex items-center justify-center">
		<div class="ml-auto flex items-center space-x-3">
			<button
				ultra-primary
				*ngIf="isEditMode"
				(click)="onSave.emit()"
				color="primary"
				[disabled]="!isSaveButtonActive"
				[svgIcon]="'check_circle'"
				[content]="'shared|record-header|save'"></button>

			<button
				*ngIf="isEditMode"
				[disabled]="!allowEdit"
				(click)="onCancel.emit()"
				[svgIcon]="'block'"
				ultra-primary></button>

			<button
				*ngIf="!isDeleted && !isEditMode"
				[disabled]="!allowEdit"
				(click)="onEdit.emit()"
				color="primary"
				[svgIcon]="'edit'"
				[content]="'shared|record-header|edit'"
				ultra-primary></button>

			<button
				ultra-primary
				class="h-9"
				[disabled]="true"
				[svgIcon]="'download'"
				(click)="this.exportRecord.emit()"></button>
		</div>
	</div>
</div>
