import {
	VehicleRoadWorthyChecksFilters,
	VehicleRoadWorthyCheckStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsRoadWorthyChecks(
	value: VehicleRoadWorthyCheckStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === VehicleRoadWorthyCheckStatusEnum.ACTIVE) {
		let filters: VehicleRoadWorthyChecksFilters = {
			statuses: [VehicleRoadWorthyCheckStatusEnum.ACTIVE],
		};
		return {
			filters: filters,
			moduleUrl: ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS,
		};
	} else if (value === VehicleRoadWorthyCheckStatusEnum.EXPIRED) {
		let filters: VehicleRoadWorthyChecksFilters = {
			statuses: [VehicleRoadWorthyCheckStatusEnum.EXPIRED],
		};
		return {
			filters: filters,
			moduleUrl: ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS,
		};
	} else if (value === VehicleRoadWorthyCheckStatusEnum.PENDING) {
		let filters: VehicleRoadWorthyChecksFilters = {
			statuses: [VehicleRoadWorthyCheckStatusEnum.PENDING],
		};
		return {
			filters: filters,
			moduleUrl: ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS,
		};
	} else {
		let filters: VehicleRoadWorthyChecksFilters = {
			statuses: [null],
		};
		return {
			filters: filters,
			moduleUrl: ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS,
		};
	}
}
