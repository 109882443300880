import {
	RentalReservationsFilters,
	RentalReservationStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsRentalReservations(
	value: RentalReservationStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === RentalReservationStatusEnum.CANCELLED) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.CANCELLED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else if (value === RentalReservationStatusEnum.COMPLETED) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.COMPLETED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else if (value === RentalReservationStatusEnum.CONFIRMED) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.CONFIRMED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else if (value === RentalReservationStatusEnum.EXPIRED) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.EXPIRED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else if (value === RentalReservationStatusEnum.NO_SHOW) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.NO_SHOW],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else if (value === RentalReservationStatusEnum.PENDING) {
		let filters: RentalReservationsFilters = {
			statuses: [RentalReservationStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	} else {
		let filters: RentalReservationsFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_RESERVATIONS };
	}
}
