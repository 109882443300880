<app-floating-menu-container *ngIf="isMobileView">
	<button
		*ngIf="!menuOpened"
		class="floating-menu-main-button flex items-center justify-center w-14 h-14 border-none rounded-full bg-blue-700 text-white cursor-pointer transition-colors duration-100 ease-in-out hover:bg-blue-700 fixed bottom-5 left-5 z-50"
		(click)="toggleMenu()">
		<mat-icon [ngStyle]="{ color: 'white' }">menu</mat-icon>
	</button>
	<button
		*ngIf="menuOpened"
		class="floating-menu-main-button flex items-center justify-center w-14 h-14 border-none rounded-full bg-blue-700 text-white cursor-pointer transition-colors duration-100 ease-in-out hover:bg-blue-700 fixed bottom-5 left-5 z-50"
		(click)="toggleMenu()">
		<mat-icon [ngStyle]="{ color: 'white' }">close</mat-icon>
	</button>
	<div
		[ngClass]="{
			'flex flex-col gap-2 fixed bottom-22 left-5 z-50 transition-all': true,
			'opacity-0 transform  pointer-events-none': !menuOpened,
			'opacity-1 transform  pointer-events-auto': menuOpened,
		}">
		<app-floating-menu-button
			*ngIf="isEditMode"
			[svgIcon]="'check'"
			[iconColor]="'white'"
			(onButtonClicked)="onSaveButtonClick()" />
		<app-floating-menu-button
			*ngIf="showEditButton && !isEditMode"
			[svgIcon]="'edit'"
			[iconColor]="'white'"
			(onButtonClicked)="onEditButtonClick()" />
		<app-floating-menu-button
			*ngIf="showCancelButton && isEditMode"
			[svgIcon]="'block'"
			[iconColor]="'white'"
			(onButtonClicked)="onCancelButtonClick()" />
		<app-floating-menu-button
			*ngIf="showExportButton"
			[svgIcon]="'file_download'"
			[iconColor]="'white'"
			(onButtonClicked)="onExportButtonClick()" />
	</div>
</app-floating-menu-container>
