<div
	class="flex items-center gap-1 border rounded-full px-2 py-1 bg-green-500 bg-opacity-10 border-green-500"
	*ngIf="progressState === progressStateEnum.saved">
	<mat-icon
		class="icon-size-5 text-green-500"
		[svgIcon]="'check_circle'"></mat-icon>
	<span class="text-sm text-green-600 py-0.5">Saved</span>
</div>

<div
	class="flex items-center gap-1 border rounded-full px-2 py-1 bg-gray-500 bg-opacity-10 border-gray-300"
	*ngIf="progressState === progressStateEnum.progress">
	<mat-spinner class="icon-size-4"></mat-spinner>
	<span class="text-sm duration-200 py-0.5 text-gray-500">Saving...</span>
</div>

<div
	class="flex items-center gap-1 border rounded-full px-2 py-1 bg-red-500 bg-opacity-10 border-red-500"
	*ngIf="progressState === progressStateEnum.error">
	<mat-icon
		class="icon-size-5 text-red-500"
		[svgIcon]="'mat_outline:error_outline'"></mat-icon>
	<span class="text-sm text-red-600 py-0.5">Error</span>
</div>
