import { SuppliersFilters, SupplierStatusEnum } from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsSuppliers(value: SupplierStatusEnum): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === SupplierStatusEnum.ACTIVE) {
		let filters: SuppliersFilters = {
			statuses: [SupplierStatusEnum.ACTIVE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.SUPPLIERS };
	} else if (value === SupplierStatusEnum.CLOSED) {
		let filters: SuppliersFilters = {
			statuses: [SupplierStatusEnum.CLOSED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.SUPPLIERS };
	} else if (value === SupplierStatusEnum.ON_HOLD) {
		let filters: SuppliersFilters = {
			statuses: [SupplierStatusEnum.ON_HOLD],
		};
		return { filters: filters, moduleUrl: ModulesEnum.SUPPLIERS };
	} else {
		let filters: SuppliersFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.SUPPLIERS };
	}
}
