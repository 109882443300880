import {
	ChangeDetectorRef,
	Component,
	effect,
	EventEmitter,
	input,
	Input,
	Output,
} from '@angular/core';
import { SelectItem } from 'app/shared/types/select-item.type';

@Component({
	selector: 'filter-multiselect-dropdown',
	templateUrl: './filter-multiselect-dropdown.component.html',
	styleUrls: ['./filter-multiselect-dropdown.component.scss'],
	host: {
		class: 'w-full',
	},
})
export class FilterMultiselectDropdownComponent<T, K> {
	@Input({ required: true }) filterTitle: string;
	@Input({ required: true }) filterOptions: SelectItem<T>[];
	@Input() public navigationContextSelections: K[];
	@Output() onFilterSelected: EventEmitter<T[]> = new EventEmitter<T[]>();
	public filteredFilters: SelectItem<T>[] = [];
	selectedFilters = input([]);

	constructor(private _cdr: ChangeDetectorRef) {
		effect(() => {
			if (!this.selectedFilters()) {
				this.filteredFilters = [];
				this._cdr.markForCheck();
				return;
			}
			const returnFilters = this.filterOptions.filter((option: any) => {
				return this.selectedFilters().some(
					(item) => item === option.value || item === option.value.id,
				);
			});
			this.filteredFilters = returnFilters;
			this._cdr.markForCheck();
		});
	}

	public onFiltersChange(selection: SelectItem<T>[]): void {
		this.filteredFilters = selection;
		this.onFilterSelected.emit(selection.map((item) => item.value));
	}
}
