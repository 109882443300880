import { Pipe, PipeTransform } from '@angular/core';

import { SelectItem } from '../../../types/select-item.type';

@Pipe({
	name: 'getTranslationKeyFromValue',
	standalone: true,
})
export class GetTranslationKeyForValuePipe<T> implements PipeTransform {
	constructor() {}
	transform(value: T, filterOptions: SelectItem<T>[]): any {
		if (!value || !filterOptions) {
			return '';
		}
		const item = filterOptions.find((option) => option.value === value);
		return item ? item.displayValue : '';
	}
}
