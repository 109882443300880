import { Component, EventEmitter, Input, Output } from '@angular/core';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { AppContextService } from '../../../services/app-context.service';

@Component({
	selector: 'app-record-footer',
	templateUrl: './record-footer.component.html',
	animations: fuseAnimations,
})
export class RecordFooterComponent {
	@Input({ required: true }) isEditMode: boolean;
	@Input() isDeleted: boolean;
	@Input() allowEdit: boolean;
	@Input() isSaveButtonActive: boolean = true;
	@Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSave: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
	@Output() exportRecord: EventEmitter<void> = new EventEmitter<void>();

	constructor(private _context: AppContextService) {
		this.allowEdit = this._context.authzService.allowEdit();
	}
}
