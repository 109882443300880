import {
	TrainingAgreementsFilters,
	TrainingAgreementStatusEnum,
} from 'ultramarine-shared';

import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsTrainingAgreements(
	value: TrainingAgreementStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === TrainingAgreementStatusEnum.CANCELLED) {
		const filters: TrainingAgreementsFilters = {
			statuses: [TrainingAgreementStatusEnum.CANCELLED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	} else if (value === TrainingAgreementStatusEnum.COMPLETED) {
		const filters: TrainingAgreementsFilters = {
			statuses: [TrainingAgreementStatusEnum.COMPLETED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	} else if (value === TrainingAgreementStatusEnum.IN_PROGRESS) {
		const filters: TrainingAgreementsFilters = {
			statuses: [TrainingAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	}

	if (value === TrainingAgreementStatusEnum.PENDING) {
		const filters: TrainingAgreementsFilters = {
			statuses: [TrainingAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	} else {
		const filters: TrainingAgreementsFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	}
}
