<a
	(click)="$event.stopPropagation()"
	[routerLink]="[moduleUrl() | contextNavigateList]"
	[queryParams]="{ filters: filters() }">
	<app-status-pill
		[hoverUnderline]="true"
		class="cursor-pointer"
		[module]="module()"
		[value]="value()"
		[pillColor]="pillColor()" />
</a>
