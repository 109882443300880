import { Component, computed, input } from '@angular/core';
import { RecordColorEnum } from 'ultramarine-shared';

import { GenericPillComponent } from '../generic-pill/generic-pill.component';
import { ModulesEnum } from '../list-table/modules.enum';

import { statusToTranslation } from './status-to-translation';

@Component({
	selector: 'app-status-pill',
	templateUrl: 'status-pill.component.html',
	standalone: true,
	imports: [GenericPillComponent],
})
export class StatusPillComponent {
	value = input<string>();
	pillColor = input<RecordColorEnum>();
	module = input<ModulesEnum>();
	hoverUnderline = input(false);
	translationValue = computed(() => {
		return statusToTranslation(this.value(), this.module());
	});
}
