import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DialogsService {
	openDeleteRecordDialog(actions: {
		confirmed?: () => void;
		cancelled?: () => void;
	}): void {
		const result = window.confirm(
			'Are you sure you want to delete? This action cannot be undone.',
		);
		if (result && actions.confirmed) {
			actions.confirmed();
		} else if (!result && actions.cancelled) {
			actions.cancelled();
		}
	}

	openUnsavedChangesDialog(actions: {
		confirmed?: () => void;
		cancelled?: () => void;
	}): void {
		const result = window.confirm(
			'You have unsaved changes. Are you sure you want to leave this page?',
		);

		if (result && actions.confirmed) {
			actions.confirmed();
		} else if (!result && actions.cancelled) {
			actions.cancelled();
		}
	}

	confirmUnsavedChangesAction(): Observable<boolean> {
		const result = window.confirm(
			'You have unsaved changes. Are you sure you want to leave this page?',
		);
		return of(result);
	}
}
