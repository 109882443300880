import {
	LeaseAgreementsFilters,
	LeaseAgreementStatusEnum,
	RentalAgreementsFilters,
	RentalAgreementStatusEnum,
	TrainingAgreementsFilters,
	TrainingAgreementStatusEnum,
	VehicleMaintenancesFilters,
	VehicleMaintenanceStatusEnum,
	VehiclesFilters,
	VehicleStatusEnum,
} from 'ultramarine-shared';

import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsVehicles(
	value: VehicleStatusEnum,
	recordId: number,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === VehicleStatusEnum.RENTED) {
		const filters: RentalAgreementsFilters = {
			vehicles: [recordId],
			statuses: [RentalAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	} else if (value === VehicleStatusEnum.IN_TRAINING) {
		const filters: TrainingAgreementsFilters = {
			vehicles: [recordId],
			statuses: [TrainingAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	} else if (value === VehicleStatusEnum.RESERVED_TRAINING) {
		const filters: TrainingAgreementsFilters = {
			vehicles: [recordId],
			statuses: [TrainingAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.TRAINING_AGREEMENTS };
	} else if (value === VehicleStatusEnum.IN_MAINTENANCE) {
		const filters: VehicleMaintenancesFilters = {
			vehicles: [recordId],
			statuses: [VehicleMaintenanceStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	} else if (value === VehicleStatusEnum.RESERVED) {
		const filters: RentalAgreementsFilters = {
			vehicles: [recordId],
			statuses: [RentalAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	} else if (value === VehicleStatusEnum.LEASED) {
		const filters: LeaseAgreementsFilters = {
			vehicles: [recordId],
			statuses: [LeaseAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	} else if (value === VehicleStatusEnum.IN_PARK) {
		const filters: VehiclesFilters = {
			statuses: [VehicleStatusEnum.IN_PARK],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	} else if (value === VehicleStatusEnum.HARD_HOLD) {
		const filters: VehiclesFilters = {
			statuses: [VehicleStatusEnum.HARD_HOLD],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	} else if (value === VehicleStatusEnum.ORDERED) {
		const filters: LeaseAgreementsFilters = {
			vehicles: [recordId],
			statuses: [LeaseAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	} else if (value === VehicleStatusEnum.SCRAPPED) {
		const filters: VehiclesFilters = {
			statuses: [VehicleStatusEnum.SCRAPPED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	} else if (value === VehicleStatusEnum.RESERVED_MAINTENANCE) {
		const filters: VehicleMaintenancesFilters = {
			vehicles: [recordId],
			statuses: [VehicleMaintenanceStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	} else if (value === VehicleStatusEnum.SOLD) {
		const filters: VehiclesFilters = {
			statuses: [VehicleStatusEnum.SOLD],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	} else if (value === VehicleStatusEnum.OUT_OF_SERVICE) {
		const filters: VehiclesFilters = {
			statuses: [VehicleStatusEnum.OUT_OF_SERVICE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	} else {
		const filters: VehiclesFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLES };
	}
}
