import {
	VehicleDamagesFilters,
	VehicleDamageStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsDamages(value: VehicleDamageStatusEnum): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === VehicleDamageStatusEnum.PENDING) {
		let filters: VehicleDamagesFilters = {
			statuses: [VehicleDamageStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_DAMAGES };
	} else if (value === VehicleDamageStatusEnum.IGNORED) {
		let filters: VehicleDamagesFilters = {
			statuses: [VehicleDamageStatusEnum.IGNORED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_DAMAGES };
	} else if (value === VehicleDamageStatusEnum.REPAIRED) {
		let filters: VehicleDamagesFilters = {
			statuses: [VehicleDamageStatusEnum.REPAIRED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_DAMAGES };
	} else {
		let filters: VehicleDamagesFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_DAMAGES };
	}
}
