<span
	matTooltip="{{ tooltip }}"
	[class]="
		'inline-flex items-center font-bold h-5 text-xs px-2 rounded-full tracking-wide uppercase ' +
		clickableClass()
	"
	[ngClass]="{
		'gray-um-pill': pillColor === colors.GRAY,
		'red-um-pill': pillColor === colors.RED,
		'orange-um-pill': pillColor === colors.ORANGE,
		'amber-um-pill': pillColor === colors.AMBER,
		'yellow-um-pill': pillColor === colors.YELLOW,
		'lime-um-pill': pillColor === colors.LIME,
		'green-um-pill': pillColor === colors.GREEN,
		'emerald-um-pill': pillColor === colors.EMERALD,
		'teal-um-pill': pillColor === colors.TEAL,
		'cyan-um-pill': pillColor === colors.CYAN,
		'sky-um-pill': pillColor === colors.SKY,
		'blue-um-pill': pillColor === colors.BLUE,
		'indigo-um-pill': pillColor === colors.INDIGO,
		'violet-um-pill': pillColor === colors.VIOLET,
		'purple-um-pill': pillColor === colors.PURPLE,
		'fuchsia-um-pill': pillColor === colors.FUCHSIA,
		'pink-um-pill': pillColor === colors.PINK,
		'rose-um-pill': pillColor === colors.ROSE,
		'slate-um-pill': pillColor === colors.SLATE,
		'zinc-um-pill': pillColor === colors.ZINC,
		'stone-um-pill': pillColor === colors.STONE,
		'neutral-um-pill': pillColor === colors.NEUTRAL,
		'default-um-pill': pillColor === colors.DEFAULT,
		'fallback-um-pill': !isValidColor(pillColor),
	}">
	<span class="leading-relaxed whitespace-nowrap items-center">
		{{ value | transloco }}
	</span>
</span>
