import { ModulesEnum } from '../list-table/modules.enum';

import { getContextNavigateArgsCustomers } from './pill-context-modules/get-context-nav-args-customers';
import { getContextNavigateArgsDamages } from './pill-context-modules/get-context-nav-args-damages';
import { getContextNavigateArgsLeaseAgreements } from './pill-context-modules/get-context-nav-args-lease-agreements';
import { getContextNavigateArgsLocations } from './pill-context-modules/get-context-nav-args-locations';
import { getContextNavigateArgsMaintenances } from './pill-context-modules/get-context-nav-args-maintenances';
import { getContextNavigateArgsRentalAgreements } from './pill-context-modules/get-context-nav-args-rental-agreements';
import { getContextNavigateArgsRentalReservations } from './pill-context-modules/get-context-nav-args-rental-reservations';
import { getContextNavigateArgsRoadTaxes } from './pill-context-modules/get-context-nav-args-road-taxes';
import { getContextNavigateArgsRoadWorthyChecks } from './pill-context-modules/get-context-nav-args-road-worthy-checks';
import { getContextNavigateArgsStaff } from './pill-context-modules/get-context-nav-args-staff';
import { getContextNavigateArgsSuppliers } from './pill-context-modules/get-context-nav-args-suppliers';
import { getContextNavigateArgsTrainingAgreements } from './pill-context-modules/get-context-nav-args-training-agreements';
import { getContextNavigateArgsVehicles } from './pill-context-modules/get-context-nav-args-vehicles';

export function getContextNavigateArgs(
	module: ModulesEnum,
	value: any,
	recordId: number,
) {
	switch (module) {
		case ModulesEnum.VEHICLES:
			return getContextNavigateArgsVehicles(value, recordId);
		case ModulesEnum.VEHICLE_ROAD_TAXES:
			return getContextNavigateArgsRoadTaxes(value);
		case ModulesEnum.VEHICLE_ROAD_WORTHY_CHECKS:
			return getContextNavigateArgsRoadWorthyChecks(value);
		case ModulesEnum.VEHICLE_MAINTENANCES:
			return getContextNavigateArgsMaintenances(value);
		case ModulesEnum.VEHICLE_DAMAGES:
			return getContextNavigateArgsDamages(value);
		case ModulesEnum.LOCATIONS:
			return getContextNavigateArgsLocations(value);
		case ModulesEnum.STAFF:
			return getContextNavigateArgsStaff(value);
		case ModulesEnum.CUSTOMERS:
			return getContextNavigateArgsCustomers(value);
		case ModulesEnum.SUPPLIERS:
			return getContextNavigateArgsSuppliers(value);
		case ModulesEnum.RENTAL_RESERVATIONS:
			return getContextNavigateArgsRentalReservations(value);
		case ModulesEnum.RENTAL_AGREEMENTS:
			return getContextNavigateArgsRentalAgreements(value);
		case ModulesEnum.LEASE_AGREEMENTS:
			return getContextNavigateArgsLeaseAgreements(value);
		case ModulesEnum.TRAINING_AGREEMENTS:
			return getContextNavigateArgsTrainingAgreements(value);
		default:
			return undefined; // or handle an unexpected module case
	}
}
