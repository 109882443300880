import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, signal } from '@angular/core';

import { ModulesEnum } from '../components/list-table/modules.enum';

@Injectable({
	providedIn: 'root',
})
export class PortalService {
	overlayRef: OverlayRef;
	componentRef: ComponentRef<any>;
	componentPortal: ComponentPortal<any>;

	constructor(private _overlay: Overlay) {}

	showRecordPortal(
		component: ComponentType<any>,
		contextData: any,
		id?: number,
		originModuleUrl?: ModulesEnum,
		code?: string,
	) {
		this.componentPortal = new ComponentPortal(component);
		const positionStrategy = this._overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();
		this.overlayRef = this._overlay.create({
			positionStrategy,
			hasBackdrop: true,
			maxWidth: '700px',
			width: '100%',
		});
		const componentRef = this.overlayRef.attach(this.componentPortal);
		const outsideSub = this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.detach();
			outsideSub.unsubscribe();
		});
		componentRef.instance.contextData = signal(contextData);
		componentRef.instance.id.set(id);
		componentRef.instance.code.set(code);
		componentRef.instance.originModuleUrl.set(originModuleUrl);
		const onSuccessSubscription = componentRef.instance.onSuccess.subscribe(
			() => {
				this.hideRecordPortal();
				onCloseSubscription.unsubscribe();
				onSuccessSubscription.unsubscribe();
			},
		);
		const onCloseSubscription = componentRef.instance.onClose.subscribe(() => {
			this.hideRecordPortal();
			onCloseSubscription.unsubscribe();
			onSuccessSubscription.unsubscribe();
		});
	}

	private hideRecordPortal() {
		this.overlayRef.detach();
	}
}
