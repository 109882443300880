<mat-form-field
	class="fuse-mat-dense fuse-mat-rounded w-full app-mat-select-placeholder"
	[subscriptSizing]="'dynamic'">
	<mat-label>{{ filterTitle | transloco }}</mat-label>
	<mat-select
		[placeholder]="'shared|all-filters-placeholder' | transloco"
		[ngModel]="filteredFilters"
		(ngModelChange)="onFiltersChange($event)"
		multiple>
		<!-- If we have multiselect enabled show custom trigger -->
		<mat-select-trigger>
			<div class="flex items-center">
				<div class="flex items-center">
					<span>
						{{
							filteredFilters.length > 0
								? (filteredFilters[0].value
									| getTranslationKeyFromValue: filterOptions
									| transloco)
								: ''
						}}
					</span>

					<span class="mx-1">
						<app-color-bubble
							*ngIf="filteredFilters[0] && filteredFilters[0].pillColor"
							[color]="filteredFilters[0].pillColor" />
					</span>
				</div>
				<span
					class="text-hint text-sm"
					*ngIf="(filteredFilters?.length || 0) > 1">
					(+{{ (filteredFilters?.length || 0) - 1 }}
					{{ 'shared|other' | transloco }})
				</span>
			</div>
		</mat-select-trigger>
		<mat-option
			*ngFor="let filterOption of filterOptions"
			[value]="filterOption">
			<div class="flex justify-between items-center">
				{{ filterOption.displayValue | transloco }}
				<app-color-bubble
					*ngIf="filterOption.pillColor"
					[color]="filterOption.pillColor" />
			</div>
		</mat-option>
	</mat-select>
</mat-form-field>
