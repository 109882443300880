import { CustomersFilters, CustomerStatusEnum } from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsCustomers(value: CustomerStatusEnum): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === CustomerStatusEnum.ACTIVE) {
		let filters: CustomersFilters = {
			statuses: [CustomerStatusEnum.ACTIVE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.CUSTOMERS };
	} else if (value === CustomerStatusEnum.CLOSED) {
		let filters: CustomersFilters = {
			statuses: [CustomerStatusEnum.CLOSED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.CUSTOMERS };
	} else if (value === CustomerStatusEnum.ON_HOLD) {
		let filters: CustomersFilters = {
			statuses: [CustomerStatusEnum.ON_HOLD],
		};
		return { filters: filters, moduleUrl: ModulesEnum.CUSTOMERS };
	} else {
		let filters: CustomersFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.CUSTOMERS };
	}
}
