export enum ModulesEnum {
	LOCATIONS = 'locations',
	USERS = 'users',
	STAFF = 'staff',
	CUSTOMERS = 'customers',
	PRICING_PROFILES = 'pricing-profiles',
	SUPPLIERS = 'suppliers',
	RENTAL_RESERVATIONS = 'rental-reservations',
	RENTAL_AGREEMENTS = 'rental-agreements',
	TRAINING_AGREEMENTS = 'training-agreements',
	VEHICLE_DAMAGES = 'vehicle-damages',
	LEASE_AGREEMENTS = 'lease-agreements',
	VEHICLES = 'vehicles',
	VEHICLE_INSURANCES = 'vehicle-insurances',
	VEHICLE_MAINTENANCES = 'vehicle-maintenances',
	VEHICLE_CATEGORIES = 'vehicle-categories',
	VEHICLE_REGISTRATIONS = 'vehicle-registrations',
	VEHICLE_ROAD_TAXES = 'vehicle-road-taxes',
	VEHICLE_ROAD_WORTHY_CHECKS = 'vehicle-road-worthy-checks',
	TAGS = 'tags',
	VEHICLE_COLORS = 'vehicle-colors',
	VEHICLE_MAKES = 'vehicle-makes',
}
