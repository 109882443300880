import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard
	implements CanDeactivate<CanComponentDeactivate>
{
	canDeactivate(
		component: CanComponentDeactivate,
		currentRoute,
		currentState,
		nextState,
	): Observable<boolean> | Promise<boolean> | boolean {
		const currentEntityId = currentState.url.split('/')[3];
		const nextEntityId = nextState?.url.split('/')[3];
		if (component.canDeactivate && currentEntityId !== nextEntityId) {
			return component.canDeactivate();
		} else {
			return true;
		}
	}
}
