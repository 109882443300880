import {
	LeaseAgreementsFilters,
	LeaseAgreementStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsLeaseAgreements(
	value: LeaseAgreementStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === LeaseAgreementStatusEnum.CANCELLED) {
		let filters: LeaseAgreementsFilters = {
			statuses: [LeaseAgreementStatusEnum.CANCELLED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	} else if (value === LeaseAgreementStatusEnum.COMPLETED) {
		let filters: LeaseAgreementsFilters = {
			statuses: [LeaseAgreementStatusEnum.COMPLETED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	} else if (value === LeaseAgreementStatusEnum.IN_PROGRESS) {
		let filters: LeaseAgreementsFilters = {
			statuses: [LeaseAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	}

	if (value === LeaseAgreementStatusEnum.PENDING) {
		let filters: LeaseAgreementsFilters = {
			statuses: [LeaseAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	} else {
		let filters: LeaseAgreementsFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LEASE_AGREEMENTS };
	}
}
