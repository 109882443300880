import { LocationsFilters, LocationStatusEnum } from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsLocations(value: LocationStatusEnum): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === LocationStatusEnum.OPEN) {
		let filters: LocationsFilters = {
			statuses: [LocationStatusEnum.OPEN],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LOCATIONS };
	} else if (value === LocationStatusEnum.CLOSED) {
		let filters: LocationsFilters = {
			statuses: [LocationStatusEnum.CLOSED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LOCATIONS };
	} else {
		let filters: LocationsFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.LOCATIONS };
	}
}
