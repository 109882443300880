import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'contextNavigateList',
	standalone: true,
})
export class ContextNavigateListPipe implements PipeTransform {
	transform(moduleUrl: string): string {
		return `/admin/${moduleUrl}`;
	}
}
