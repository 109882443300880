import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiRequestFilters } from 'ultramarine-shared';

@Injectable({
	providedIn: 'root',
})
export class NavigationContextService {
	constructor(private _router: Router) {}

	public navigationFilters: BehaviorSubject<ApiRequestFilters> =
		new BehaviorSubject<ApiRequestFilters | null>(null);

	public navigateToListWithContext<T>(moduleUrl: string, filters: T) {
		this._router.navigate(['admin', moduleUrl], {
			queryParams: { filters: JSON.stringify(filters) },
		});
	}

	public navigateToNewRecordWithContext(
		moduleUrl: string,
		taskData: any,
	): void {
		this._router.navigate(['admin', moduleUrl, 'new'], {
			queryParams: { data: JSON.stringify(taskData) },
			queryParamsHandling: 'merge',
		});
	}
}
