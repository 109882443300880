import {
	RentalAgreementsFilters,
	RentalAgreementStatusEnum,
} from 'ultramarine-shared';

import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsRentalAgreements(
	value: RentalAgreementStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === RentalAgreementStatusEnum.CANCELLED) {
		const filters: RentalAgreementsFilters = {
			statuses: [RentalAgreementStatusEnum.CANCELLED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	} else if (value === RentalAgreementStatusEnum.COMPLETED) {
		const filters: RentalAgreementsFilters = {
			statuses: [RentalAgreementStatusEnum.COMPLETED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	} else if (value === RentalAgreementStatusEnum.IN_PROGRESS) {
		const filters: RentalAgreementsFilters = {
			statuses: [RentalAgreementStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	}

	if (value === RentalAgreementStatusEnum.PENDING) {
		const filters: RentalAgreementsFilters = {
			statuses: [RentalAgreementStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	} else {
		const filters: RentalAgreementsFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.RENTAL_AGREEMENTS };
	}
}
