<div class="ultramarine-page-header">
	<div class="flex-1 min-w-0">
		<div class="flex items-center justify-between w-full">
			<span class="flex items-center content-center">
				<a
					class="flex sm:hidden"
					*ngIf="showBackToList"
					ultra-button
					[svgIcon]="'arrow_back'"
					[routerLink]="'../..'">
				</a>

				<a
					class="hidden sm:flex"
					*ngIf="showBackToList"
					ultra-button
					[svgIcon]="icon"
					[content]="moduleName"
					[routerLink]="'../..'">
				</a>
				<span class="mx-1 hidden sm:block"> / </span>
				<button
					(click)="onCopyTitle()"
					ultra-button
					[content]="headerData.title"
					*ngIf="headerData.title && !this.copiedTitleToClipboard"></button>
				<button
					ultra-button
					[svgIcon]="'check'"
					[content]="'Copied'"
					*ngIf="this.copiedTitleToClipboard"></button>
				<span
					*ngIf="!headerData.title && isNew"
					ultra-button
					disabled="true"
					[content]="'New Record'"></span>

				<app-clickable-status-pill
					[module]="module"
					[recordId]="recordData?.id"
					[value]="headerData.status"
					[pillColor]="headerData.color"
					*ngIf="headerData.status && !headerData.isDraft" />

				<app-generic-pill
					*ngIf="headerData.isDraft"
					class="mx-1"
					[value]="'Draft'"
					[pillColor]="'um-neutral'" />

				<ng-content select=".record-menu" />

				<!-- <button
					ultra-button
					*ngIf="!hideMenuToggle && !isNew"
					#anchor
					(click)="showHeaderMenu()"
					[svgIcon]="'more_horiz'"></button> -->
			</span>

			<!-- Actions -->
			<div class="flex items-center gap-2">
				<!-- <ng-container *ngIf="isEditMode && !isNew">
					<app-progress-indicator
				></app-progress-indicator>
				</ng-container> -->

				<button
					ultra-primary
					*ngIf="isEditMode"
					class="hidden sm:flex"
					(click)="onSave.emit()"
					color="primary"
					[disabled]="!isSaveButtonActive"
					[svgIcon]="'check_circle'"
					[content]="'shared|record-header|save'"></button>

				<button
					*ngIf="isEditMode"
					[disabled]="!(isPresent | async) || !allowEdit"
					(click)="onCancel.emit()"
					[svgIcon]="'block'"
					class="hidden sm:flex"
					ultra-primary></button>

				<button
					*ngIf="!headerData.isDeleted && !isEditMode"
					[disabled]="!(isPresent | async) || !allowEdit"
					(click)="onEdit.emit()"
					color="primary"
					[svgIcon]="'edit'"
					[content]="'shared|record-header|edit'"
					ultra-primary
					class="hidden sm:flex"></button>

				<button
					ultra-primary
					[disabled]="true"
					class="hidden sm:block h-9"
					[svgIcon]="'download'"
					(click)="this.exportRecord.emit()"></button>

				<button
					ultra-primary
					class="block h-9"
					*ngIf="!headerData.isDeleted"
					[svgIcon]="'format_list_bulleted'"
					[disabled]="isNew || !allowEdit"
					(click)="onToggleSidebar.emit()"></button>
			</div>
		</div>
	</div>
</div>

<ng-template #templateRef>
	<app-record-header-menu
		[setHeaderData]="headerData"
		(onMarkAsReadyClicked)="onMarkAsReady.emit(void)"
		(onDeleteClicked)="onDelete.emit(void)" />
</ng-template>
