import {
	ChangeDetectorRef,
	Component,
	effect,
	EventEmitter,
	input,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { SelectItem } from 'app/shared/types/select-item.type';
@Component({
	selector: 'filter-dropdown',
	templateUrl: './filter-dropdown.component.html',
	styleUrls: ['./filter-dropdown.component.scss'],
	host: {
		class: 'w-full',
	},
})
export class FilterDropdownComponent<T> {
	@Input({ required: true }) filterTitle: string = '';
	public filterSelected: T;
	@Input({ required: true }) filterOptions: SelectItem<T>[] = [];
	@Output() onFilterSelected: EventEmitter<T> = new EventEmitter<T>();
	selectedFilter = input<any>();

	constructor(private _cdr: ChangeDetectorRef) {
		effect(() => {
			if (!this.selectedFilter()) {
				this.filterSelected = null;
				this._cdr.markForCheck();
				return;
			}
			this.filterSelected = this.selectedFilter();
			this._cdr.markForCheck();
		});
	}

	public onFiltersChange(selection: T): void {
		this.onFilterSelected.emit(selection);
	}
}
