import { StaffFilters, StaffStatusEnum } from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsStaff(value: StaffStatusEnum): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === StaffStatusEnum.ACTIVE) {
		let filters: StaffFilters = {
			statuses: [StaffStatusEnum.ACTIVE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.STAFF };
	} else if (value === StaffStatusEnum.INACTIVE) {
		let filters: StaffFilters = {
			statuses: [StaffStatusEnum.INACTIVE],
		};
		return { filters: filters, moduleUrl: ModulesEnum.STAFF };
	} else {
		let filters: StaffFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.STAFF };
	}
}
