import { NgIf } from '@angular/common';
import {
	Component,
	HostBinding,
	HostListener,
	input,
	Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'div[ultra-menu-item-wrapper], span[ultra-menu-item-wrapper]',
	templateUrl: 'ultra-menu-item-wrapper.component.html',
	standalone: true,
	imports: [MatIconModule, NgIf, MatTooltipModule, TranslocoModule],
})
export class UltraMenuItemWrapperComponent {
	tooltip = input();
}
