import { Component, computed, effect, input, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RecordColorEnum } from 'ultramarine-shared';

import { ContextNavigateListPipe } from '../../pipes/context-navigate-list.pipe';
import { ModulesEnum } from '../list-table/modules.enum';
import { StatusPillComponent } from '../status-pill/status-pill.component';

import { getContextNavigateArgs } from './pill-context-navigate-actions';

@Component({
	selector: 'app-clickable-status-pill',
	templateUrl: 'clickable-status-pill.component.html',
	standalone: true,
	imports: [StatusPillComponent, RouterLink, ContextNavigateListPipe],
	host: {
		class: 'flex mx-1 items-center',
	},
})
export class ClickableStatusPillComponent {
	value = input<string>();
	pillColor = input<RecordColorEnum>();
	module = input<ModulesEnum>();
	recordId = input<number>();
	moduleUrl = signal(null);
	filters = signal(null);
	contextNavObject = computed<any>(() => {
		const contextNavData = getContextNavigateArgs(
			this.module(),
			this.value(),
			this.recordId(),
		);
		if (!contextNavData) {
			return;
		}
		return contextNavData;
	});
	constructor() {
		effect(
			() => {
				this.moduleUrl.set(this.contextNavObject().moduleUrl);
				this.filters.set(JSON.stringify(this.contextNavObject().filters));
			},
			{
				allowSignalWrites: true,
			},
		);
	}
}
