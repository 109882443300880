import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { SettingsService } from '../../modules/admin/settings/services/settings.service';

@Pipe({
	name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
	constructor(
		private _logger: NGXLogger,
		private _settingsService: SettingsService,
	) {}

	transform(value: number): string {
		// Log
		this._logger.info('CountryNamePipe:transform > called with value: ', value);

		// Get country name
		const countryName = this._settingsService.getCountryName(value);

		// Return
		return countryName ? countryName : null; // 'Unknown';
	}
}
