<mat-form-field
	class="fuse-mat-dense fuse-mat-rounded sm:min-w-50 w-full"
	[subscriptSizing]="'dynamic'">
	<mat-label>{{ filterTitle | transloco }}</mat-label>
	<mat-select
		[ngModel]="filterSelected"
		(ngModelChange)="onFiltersChange($event)">
		<mat-option
			*ngFor="let filterOption of filterOptions"
			[value]="filterOption.value">
			{{ filterOption.displayValue | transloco }}
		</mat-option>
	</mat-select>
</mat-form-field>
