import { Component } from '@angular/core';

@Component({
	selector: 'app-floating-menu-container',
	template: `<div class="fixed bottom-5 right-5 flex flex-col gap-2 z-50">
		<ng-content></ng-content>
	</div>`,
	standalone: true,
})
export class FloatingMenuContainerComponent {}
