import {
	VehicleMaintenancesFilters,
	VehicleMaintenanceStatusEnum,
} from 'ultramarine-shared';
import { ModulesEnum } from '../../list-table/modules.enum';

export function getContextNavigateArgsMaintenances(
	value: VehicleMaintenanceStatusEnum,
): {
	filters: any;
	moduleUrl: ModulesEnum;
} {
	if (value === VehicleMaintenanceStatusEnum.COMPLETED) {
		let filters: VehicleMaintenancesFilters = {
			statuses: [VehicleMaintenanceStatusEnum.COMPLETED],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	} else if (value === VehicleMaintenanceStatusEnum.IN_PROGRESS) {
		let filters: VehicleMaintenancesFilters = {
			statuses: [VehicleMaintenanceStatusEnum.IN_PROGRESS],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	} else if (value === VehicleMaintenanceStatusEnum.PENDING) {
		let filters: VehicleMaintenancesFilters = {
			statuses: [VehicleMaintenanceStatusEnum.PENDING],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	} else {
		let filters: VehicleMaintenancesFilters = {
			statuses: [null],
		};
		return { filters: filters, moduleUrl: ModulesEnum.VEHICLE_MAINTENANCES };
	}
}
